<template>
  <v-app>
    <v-main class="page-background py-10">
      <ServiceNavBar>
      </ServiceNavBar>
      <v-container fluid>
        <v-container>
          <h1 class="text-center my-8 page-title">
            Contact Us
          </h1>
          <v-row v-if="locations.length"
                 class="d-flex justify-center">
            <v-col cols="12"
                   md="6"
                   lg="4"
                   v-for="(location, index) in locations"
                   :key="index">
              <v-card class="pa-4 mb-4 location-card elevation-6">
                <v-img :src="location.image"
                       height="180px"
                       class="mb-3 rounded"
                       cover>
                </v-img>
                <h3 class="location-title">
                  {{ location.name }}
                </h3>
                <div class="location-details">
                  <p>
                    <strong>
                      Address:
                    </strong>
                    {{ location.address }}
                  </p>
                  <p>
                    <strong>
                      Email:
                    </strong>
                    {{ location.email }}
                  </p>
                  <p>
                    <strong>
                      Phone:
                    </strong>
                    {{ location.phone }}
                  </p>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-else
                 class="d-flex justify-center">
            <v-col cols="12"
                   md="6"
                   lg="4">
              <v-card class="pa-5 mb-4 text-center">
                <v-card-text>
                  Loading locations...
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-col cols="12"
                   md="8">
              <v-card class="pa-6 mb-4 about-card elevation-6">
                <h3 class="about-title text-center">
                  About {{ config.appname }}
                </h3>
                <p class="about-description text-center">
                  {{ config.extended_description }}
                </p>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-container>
    </v-main>
    <Footer :config="config">
    </Footer>
  </v-app>
</template>
<script>
import ServiceNavBar from "@/views/service/components/ServiceNavBar.vue";
import axios from "axios";
import Footer from "@/views/service/components/Footer.vue";
import {mapGetters, mapActions} from "vuex";

export default {
  components: {
    ServiceNavBar,
    Footer,
  },
  data() {
    return {
      locations: [],
    };
  },
  created() {
    this.fetchLocations();
    this.fetchConfig();
  },
  computed: {
    ...mapGetters(["config"]),
  },
  methods: {
    ...mapActions(["fetchConfig"]),
    async fetchLocations() {
      try {
        const response = await axios.get('/locations');
        this.locations = response.data;
      } catch (error) {
        console.error("Failed to fetch locations", error);
      }
    },
  },
};
</script>
<style scoped>
.page-background {
  background-color: #1c1c1c;
  padding: 40px 0;
  color: #c21a1a;
}

.page-title {
  color: #ffffff;
  font-weight: bold;
  font-size: 2.5rem;
  padding: 0 20px;
}

.location-card, .about-card {
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  transition: transform 0.2s, box-shadow 0.2s;
  background-color: #2a2a2a;
}

.location-card:hover, .about-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.35);
}

.location-title, .about-title {
  font-weight: bold;
  font-size: 1.5rem;
  color: #4a579f;
}

.location-details p, .about-description {
  color: #cccccc;
  font-size: 1rem;
  margin-bottom: 10px;
}

.v-card-text p {
  margin-bottom: 10px;
}

@media (max-width: 600px) {
  .page-title {
    font-size: 1.8rem;
  }

  .location-title, .about-title {
    font-size: 1.3rem;
  }

  .about-description {
    font-size: 0.9rem;
  }
}
</style>
