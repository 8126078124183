import {createRouter, createWebHistory} from 'vue-router'
import ServiceMain from "@/views/service/ServiceMain.vue";
import serviceRoutes from "@/router/serviceRoutes";

const routes = [
    {
        path: '/',
        name: 'service',
        component: ServiceMain,
    },
    ...serviceRoutes,
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
