<template>
  <v-footer class="footer"
            padless>
    <v-container fluid>
      <v-row justify="center"
             align="center"
             no-gutters>
        <v-col cols="12"
               md="6"
               class="text-center social-links">
          <v-btn icon
                 :href="config.company_url"
                 target="_blank"
                 v-if="config.company_url">
            <v-icon color="lightblue">
              mdi-web
            </v-icon>
          </v-btn>
          <v-btn icon
                 :href="config.twitter_url"
                 target="_blank"
                 v-if="config.twitter_url">
            <v-icon color="lightblue">
              mdi-twitter
            </v-icon>
          </v-btn>
          <v-btn icon
                 :href="config.instagram_url"
                 target="_blank"
                 v-if="config.instagram_url">
            <v-icon color="lightblue">
              mdi-instagram
            </v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12"
               md="6"
               class="text-center contact-info">
          <p class="contact-item">
            <v-icon color="lightblue"
                    class="mr-1">
              mdi-phone
            </v-icon>
            <a :href="'tel:' + config.phone_number"
               class="contact-link">
              {{ config.phone_number }}
            </a>
          </p>
          <p class="contact-item">
            <v-icon color="lightblue"
                    class="mr-1">
              mdi-email
            </v-icon>
            <a href="mailto:contiperformanceparts@gmail.com"
               class="contact-link">
              contiperformanceparts@gmail.com
            </a>
          </p>
        </v-col>
        <v-col cols="12"
               class="text-center footer-links">
          <v-btn class="link-btn"
                 @click="$router.push('/service-contact')">
            Contact
          </v-btn>
          <v-btn text
                 class="link-btn"
                 @click="$router.push('/privacy-policy')">
            Privacy Policy
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>
<script>
export default {
  name: "ServiceFooter",
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style scoped>
.footer {
  background-color: #2a2a2a;
  padding: 20px 0;
  color: white;
}

.social-links v-btn {
  margin: 0 5px;
}

.contact-info {
  margin-top: 10px;
}

.contact-item {
  margin: 5px 0;
  color: #ffffff;
  font-size: 1.1rem;
}

.contact-link {
  color: #cfcfff;
  text-decoration: none;
  transition: color 0.3s ease;
  font-weight: bold;
}

.contact-link:hover {
  color: #ffcc00;
}

.footer-links .link-btn {
  color: #cfcfff;
  margin: 0 5px;
  font-weight: bold;
}

.footer-links .link-btn:hover {
  color: #ffcc00;
}
</style>
