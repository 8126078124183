<template>
  <ServiceNavBar>
  </ServiceNavBar>
  <v-container>
    <v-row justify="center">
      <v-col cols="12"
             md="8">
        <v-card class="pa-5 mb-4"
                v-if="!isLocationConfirmed">
          <v-card-title>
            Select a location to continue or start an online order:
          </v-card-title>
          <v-card-text>
            <v-radio-group v-model="selectedLocation"
                           row>
              <v-radio v-for="location in locations"
                       :key="location.id"
                       :label="location.name"
                       :value="location">
              </v-radio>
            </v-radio-group>
          </v-card-text>
          <v-card-actions>
            <v-btn :disabled="!selectedLocation"
                   color="primary"
                   @click="confirmLocation"
                   block>
              Confirm Location
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card class="pa-5 mb-4"
                v-if="isLocationConfirmed && !orderType">
          <v-card-title>
            Select Order Type:
          </v-card-title>
          <v-card-text>
            <v-btn @click="selectOrderType('Takeout')"
                   color="primary"
                   class="mr-4">
              Takeout
            </v-btn>
            <v-btn @click="selectOrderType('Delivery')"
                   color="primary">
              Delivery
            </v-btn>
          </v-card-text>
        </v-card>
        <v-card v-if="isLocationConfirmed && orderType"
                class="checkout-card pa-5">
          <h1 class="checkout-title">
            Checkout
          </h1>
          <v-card class="location-info mb-4 pa-4">
            <p>
              <strong>
                Location:
              </strong>
              {{ selectedLocation.name }}
            </p>
            <p>
              <strong>
                Order Type:
              </strong>
              {{ orderType }}
            </p>
          </v-card>
          <v-list dense>
            <v-list-item v-for="item in cartItems"
                         :key="item.id"
                         class="cart-item">
              <v-row align="center"
                     class="w-100">
                <v-col cols="6">
                  <v-list-item-title class="cart-item-title">
                    {{ item.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="cart-item-subtitle">
                    Price: ${{ item.price.toFixed(2) }} | Total: ${{ (item.price * item.quantity).toFixed(2) }}
                  </v-list-item-subtitle>
                </v-col>
                <v-col cols="3"
                       class="d-flex align-center">
                  <v-text-field v-model.number="item.quantity"
                                type="number"
                                min="1"
                                class="quantity-input"
                                label="Qty"
                                hide-details
                                solo-inverted
                                @input="updateQuantity(item)">
                  </v-text-field>
                  <v-btn icon
                         color="red"
                         class="ml-2 mt-0"
                         @click="removeFromCart(item.id)">
                    <v-icon>
                      mdi-close
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-list-item>
          </v-list>
          <v-card class="discount-card mb-4 pa-4">
            <v-text-field v-model="discountCode"
                          label="Discount Code"
                          persistent-hint
                          hint="Enter your discount code"
                          solo>
            </v-text-field>
          </v-card>
          <v-row>
            <v-col cols="12">
              <h2 class="cart-total">
                Total: ${{ totalWithDelivery.toFixed(2) }}
              </h2>
            </v-col>
          </v-row>
          <v-btn color="primary"
                 block
                 @click="confirmOrder">
            Confirm Order
          </v-btn>
          <v-btn color="secondary"
                 block
                 @click="resetSelection">
            Change Location/Order Type
          </v-btn>
        </v-card>
        <v-card v-if="isEmpty"
                class="text-center">
          <v-card-title>
            Your cart is empty, return to the menu?
          </v-card-title>
          <v-card-actions class="justify-center">
            <v-btn :to="{ name: 'service' }"
                   color="primary"
                   class="mx-auto">
              Menu
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import {mapGetters, mapActions} from "vuex";
import ServiceNavBar from "@/views/service/components/ServiceNavBar.vue";
export default {
  components: {
    ServiceNavBar,
  },
  data() {
    return {
      discountCode: "",
      selectedLocation: null,
      orderType: null,
      isLocationConfirmed: false,
      deliveryCharge: 5.0,
      isEmpty: false,
      locations: [
        {id: 1, name: "123 Main St, Springfield"},
        {id: 2, name: "456 Elm St, Shelbyville"},
        {id: 3, name: "789 Oak St, Capital City"},
      ],
    };
  },
  computed: {
    ...mapGetters(["cartItems", "cartTotal"]),

    totalWithDelivery() {
      if (this.orderType === "Delivery") {
        return this.cartTotal + this.deliveryCharge;
      }
      return this.cartTotal;
    },
  },
  mounted() {
    if (this.cartItems.length === 0) {
      this.isEmpty = true;
    }
  },
  methods: {
    ...mapActions(["removeFromCart", "addToCart"]),

    updateQuantity(item) {
      if (item.quantity < 1) {
        item.quantity = 1;
      }
      this.$store.commit("UPDATE_CART_ITEM", item);
    },

    confirmOrder() {
      alert("Order confirmed!");
      this.$store.dispatch("clearCart");
      this.$router.push("/");
    },

    confirmLocation() {
      if (!this.selectedLocation) {
        alert("Please select a location to continue.");
      } else {
        this.isLocationConfirmed = true;
      }
    },

    selectOrderType(type) {
      this.orderType = type;
    },

    resetSelection() {
      this.selectedLocation = null;
      this.orderType = null;
      this.isLocationConfirmed = false;
    },
  },
};
</script>

<style scoped>
.checkout-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.cart-item-title {
  font-size: 1.25rem;
  font-weight: bold;
}

.cart-item-subtitle {
  color: #555;
}

.quantity-input {
  max-width: 70px;
  margin-right: 5px;
}

.cart-total {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: right;
  margin-top: 20px;
}

.v-btn {
  margin-top: 20px;
}

/* Main Checkout Card Styling */
.checkout-card {
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

/* Discount Code Card Styling */
.discount-card {
  background-color: white;
  border-radius: 15px;
}

.ml-2 {
  margin-left: 10px;
}

.d-flex.align-center {
  display: flex;
  align-items: center;
}

/* Location Info Card */
.location-info {
  background-color: white;
  border-radius: 10px;
  padding: 10px;
}
</style>
