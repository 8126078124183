import ServiceCheckout from "@/views/service/parts/order/ServiceCheckout.vue";
import ServiceContact from "@/views/service/contact/ServiceContact.vue";
import Login from "@/views/service/Login.vue";
import ServiceRegister from "@/views/service/ServiceRegister.vue";
import ServiceParts from "@/views/service/parts/ServiceParts.vue";
import ServiceShowcase from "@/views/service/showcase/ServiceShowcase.vue";
import ServiceAppointment from "@/views/service/appointment/ServiceAppointment.vue";

const serviceRoutes = [

    {
        path: '/service-checkout',
        name: 'service-checkout',
        component: ServiceCheckout,
    },
    {
        path: '/service-contact',
        name: 'service-contact',
        component: ServiceContact,
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
    },
    {
        path: '/service-register',
        name: 'service-register',
        component: ServiceRegister,
    },
    {
        path: '/service-parts',
        name: 'service-parts',
        component: ServiceParts,
    },
    {
        path: '/service-showcase',
        name: 'service-showcase',
        component: ServiceShowcase,
    },
    {
        path: '/service-appointment',
        name: 'service-appointment',
        component: ServiceAppointment,
    },


]
export default serviceRoutes;