import { createStore } from 'vuex';
import axios from 'axios';
import router from '../router';
const store = createStore({
  state: {
    isLoggedIn: !!localStorage.getItem('token'),
    user: null,
    cartItems: [],
    config: {},
  },
  mutations: {
    REMOVE_FROM_CART(state, itemId) {
      state.cartItems = state.cartItems.filter((item) => item.id !== itemId);
    },
    ADD_TO_CART(state, item) {
      const existingItem = state.cartItems.find((cartItem) => cartItem.id === item.id);
      if (existingItem) {
        existingItem.quantity += item.quantity || 1;
      } else {
        state.cartItems.push({
          ...item,
          quantity: item.quantity || 1,
        });
      }
    },
    UPDATE_CART_QUANTITY(state, item) {
      const existingItem = state.cartItems.find((cartItem) => cartItem.id === item.id);
      if (existingItem) {
        existingItem.quantity = item.quantity;
      }
    },
    SET_LOGGED_IN(state, status) {
      state.isLoggedIn = status;
    },
    SET_USER(state, user) {
      state.user = user;
      if (user && user.account_id) {
        localStorage.setItem('account_id', user.account_id);
      }
    },
    SET_CONFIG(state, config) {
      state.config = config;
    }
  },
  actions: {
    removeFromCart({ commit }, itemId) {
      commit("REMOVE_FROM_CART", itemId);
    },
    addToCart({ commit }, item) {
      commit("ADD_TO_CART", item);
    },
    updateCartQuantity({ commit }, item) {
      commit("UPDATE_CART_QUANTITY", item);
    },
    logout({ commit }) {
      localStorage.removeItem('token');
      localStorage.removeItem('account_id');
      commit('SET_LOGGED_IN', false);
      commit('SET_USER', null);
      router.push('/login');
    },
    async fetchConfig({ commit }) {
      try {
        const ACCOUNT_ID = localStorage.getItem('account_id');
        const response = await axios.get('/config', {
          params: {
            account_id: ACCOUNT_ID,
          },
        });
        commit('SET_CONFIG', response.data);
        return response.data;
      } catch (error) {
        console.error('Failed to fetch configuration.', error);
        return null;
      }
    },
  },
  getters: {
    cartItems: (state) => state.cartItems,
    cartTotal: (state) => {
      return state.cartItems.reduce((total, item) => {
        return total + item.price * item.quantity;
      }, 0);
    },
    cartCount: (state) => {
      return state.cartItems.reduce((count, item) => {
        return count + item.quantity;
      }, 0);
    },
    isLoggedIn: (state) => state.isLoggedIn,
    user: (state) => state.user,
    config: (state) => state.config,
  },
});

export default store;
