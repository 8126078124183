<template>
  <v-app>
    <v-main class="service-events dark-theme">
      <v-container fluid>
        <ServiceNavBar>
        </ServiceNavBar>
        <v-row class="section showcase-section"
               justify="center">
          <v-col cols="12"
                 class="text-center">
            <h2 class="section-title">Gallery</h2>
          </v-col>
          <v-col cols="12"
                 md="10"
                 class="text-center showcase-event">

            <v-carousel class="featured-slideshow"
                        cycle
                        height="400px"
                        hide-delimiters
                        show-arrows-on-hover>
              <v-carousel-item v-for="index in 13"
                               :key="index">
                <v-img :src="`/images/showcase/showcase_img_${index}.png`"
                       class="featured-img"
                       cover>
                </v-img>
              </v-carousel-item>
            </v-carousel>
            <v-card-title class="event-title">
              2024 TOY 🧸 DRIVE EVENT GALLERY
            </v-card-title>
            <v-card-subtitle class="event-description">
              Witness the highlights of our latest showcase event, featuring the best moments and experiences
              captured.
            </v-card-subtitle>
            <v-row justify="center"
                   class="showcase-images">
              <v-col v-for="index in 13"
                     :key="index"
                     :cols="12"
                     :md="index === 11 ? 6 : 4"
                     class="showcase-item"
                     v-if="index !== 11">
                <v-img :src="`/images/showcase/showcase_img_${index}.png`"
                       class="showcase-img"
                       height="300px"
                       cover>
                </v-img>
              </v-col>
            </v-row>

          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <Footer :config="config">
    </Footer>
  </v-app>
</template>
<script>
import ServiceNavBar from "@/views/service/components/ServiceNavBar.vue";
import Footer from "@/views/service/components/Footer.vue";
import {mapGetters, mapActions} from 'vuex';


export default {
  components: {
    ServiceNavBar,
    Footer,
  },
  created() {
    this.fetchConfig();
  },
  computed: {
    ...mapGetters(['config']),
  },
  methods: {
    ...mapActions(["fetchConfig"]),
  }
};
</script>
<style scoped>
.dark-theme {
  background-color: #1c1c1c;
  padding-bottom: 40px;
  color: white;
}

.section {
  margin: 40px 0;
}

.section-title {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 15px;
  color: #cfcfff;
}

.showcase-section {
  background-color: #2a2a2a;
  padding: 20px;
  border-radius: 8px;
}

.showcase-event {
  background-color: #333;
  padding: 20px;
  border-radius: 15px;
  margin-bottom: 20px;
}

.event-card {
  background-color: #333;
  border-radius: 15px;
  padding: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.event-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.featured-img {
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  margin-bottom: 15px;
}

.featured-slideshow {
  border-radius: 15px;
  overflow: hidden;
}

.event-title {
  color: #ffffff;
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
}

.event-description {
  color: #cccccc;
  font-size: 1.1rem;
  text-align: center;
  margin-bottom: 20px;
}

.showcase-images {
  margin-top: 20px;
}

.showcase-item {
  margin-bottom: 15px;
}

.showcase-img {
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;
  overflow: hidden;
}

.showcase-img:hover {
  transform: scale(1.02);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.4);
}

.showcase-img::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background: linear-gradient(
      rgba(28, 28, 28, 0) 70%,
      rgba(28, 28, 28, 0.9) 100%
  );
}
</style>
