<template>
  <v-app>
    <v-main class="service-demo dark-theme">
      <v-container fluid>
        <ServiceNavBar>
        </ServiceNavBar>
        <v-row class="banner"
               justify="center">
          <v-col cols="12">
            <v-img src="/images/banner.png"
                   height="350px"
                   width="100%"
                   cover class="rounded-img">
            </v-img>
          </v-col>
          <v-col cols="12"
                 class="text-center banner-content">
            <v-btn :style="{ backgroundColor: config.page_accent_color }"
                   class="book-appointment-btn"
                   @click="bookAppointment">
              Book an Appointment
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="section book-service-section"
               justify="center">
          <v-col cols="12"
                 md="10"
                 class="text-center">
            <h2 class="section-title">
              Our Automotive Solutions
            </h2>
            <p class="section-subtitle">
              We provide a range of expert services to meet all your automotive needs.
            </p>
            <v-divider class="divider mb-4">
            </v-divider>
            <v-row justify="center"
                   class="service-list mt-4">
              <v-col cols="12"
                     md="6"
                     class="service-item"
                     v-for="service in services"
                     :key="service.title">
                <div class="service-box">
                  <h3 class="service-title">
                    {{ service.title }}
                  </h3>
                  <p class="service-description">
                    {{ service.description }}
                  </p>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="section partners-section"
               justify="center">
          <v-col cols="12"
                 class="text-center">
            <h2 class="section-title">
              Our Trusted Partners
            </h2>
            <p class="section-subtitle">
              We are proud to collaborate with industry-leading brands.
            </p>
          </v-col>
          <v-row class="partner-list">
            <v-col cols="12"
                   md="4"
                   class="text-center partner-tile"
                   v-for="(partner, index) in partners"
                   :key="index">
              <v-avatar size="150" c
                        lass="partner-avatar">
                <v-img :src="partner.logo">
                </v-img>
              </v-avatar>
              <p>
                {{ partner.name }}
              </p>
            </v-col>
          </v-row>
        </v-row>
        <v-row class="section questions-section"
               justify="center">
          <v-col cols="12"
                 md="10"
                 class="text-center">
            <h2 class="section-title">
              Got Questions?
            </h2>
            <p class="section-subtitle">
              Here are some common questions about our services.
            </p>
            <v-divider class="divider mb-4">
            </v-divider>
            <v-row justify="center"
                   class="questions-list">
              <v-col cols="12"
                     md="6"
                     class="question-item"
                     v-for="(question, index) in questions"
                     :key="index">
                <div class="question-box">
                  <h3 class="question-title">
                    {{ question.title }}
                  </h3>
                  <p class="question-answer">
                    {{ question.answer }}
                  </p>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <Footer :config="config"/>
  </v-app>
</template>
<script>
import ServiceNavBar from "@/views/service/components/ServiceNavBar.vue";
import {mapActions, mapGetters} from "vuex";
import Footer from "@/views/service/components/Footer.vue";

export default {
  components: {
    ServiceNavBar,
    Footer,
  },
  data() {
    return {
      partners: [
        {name: 'Z1 Motorsports', logo: '/images/z1.png'},
        {name: 'Kenda Tires', logo: '/images/kenda.png'},
        {name: 'Cuyahoga Valley Career Center', logo: '/images/cvcc.png'}
      ],
      services: [
        {
          title: 'Transmission Replacement/Upgrades',
          description: 'Complete transmission replacement and clutch/flywheel upgrades to enhance performance.'
        },
        {
          title: 'Engine Replacement/Swaps',
          description: 'Engine swaps and replacements tailored to your specific needs.'
        },
        {
          title: 'VR30DDTT Turbo Replacement/Upgrades',
          description: 'Turbo replacements and upgrades for improved power and efficiency.'
        },
        {
          title: 'Preventative Maintenance & Repair',
          description: 'Fluids, oil changes, brake services, and more to keep your vehicle running smoothly.'
        },
        {title: 'Performance Upgrades', description: 'Expert bolt-on modifications for enhanced performance.'},
        {
          title: 'Final Drive Upgrades',
          description: 'Gear ratio adjustments and LSD builds for optimized performance.'
        },
        {
          title: 'Classic Car Restoration & Servicing',
          description: 'Expert restoration and servicing for classic vehicles.'
        },
        {
          title: 'Tire Services',
          description: 'Tire installations, balancing, and rotations for all track/drift needs.'
        },
        {title: 'Interstate Batteries', description: 'Quality batteries with free installation to keep you powered up.'}
      ],
      questions: [
        {
          title: 'Can I check the status of my appointment?',
          answer: 'Yes, you will receive text and email notifications about your vehicle\'s status.'
        },
        {
          title: 'How can I trust the diagnosis?',
          answer: 'We send video progress of any important information as to be 100% transparent and open.'
        }
      ],
    };
  },
  computed: {
    ...mapGetters(["config"]),
  },
  methods: {
    ...mapActions(["fetchConfig"]),
    bookAppointment() {
      this.$router.push("/service-appointment");
    },
  },
  created() {
    this.fetchConfig();
  },
};
</script>
<style scoped>
.dark-theme {
  background-color: #1c1c1c;
  padding-bottom: 40px;
  color: white;
}

.banner {
  position: relative;
  margin-bottom: 20px;
}

.banner-content {
  position: absolute;
  bottom: 20px;
  width: 100%;
  text-align: center;
}

.rounded-img {
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.4);
}

.book-appointment-btn {
  font-size: 1.0rem;
  font-weight: bold;
  color: white;
  border-radius: 25px;
  transition: background-color 0.3s ease;
}

.section {
  margin: 40px 0;
}

.section-title {
  font-size: 2rem;
  font-weight: bold;
  color: #cfcfff;
  margin-bottom: 10px;
}

.section-subtitle {
  color: #cccccc;
  font-size: 1.1rem;
  margin-bottom: 20px;
}

.questions-section {
  background-color: #2a2a2a;
  padding: 30px;
  border-radius: 12px;
}

.partners-section {
  background-color: #333;
  padding: 30px;
  border-radius: 12px;
}

.partner-tile p {
  color: #ffffff;
  font-size: 1.1rem;
  font-weight: bold;
  margin-top: 10px;
}

.service-box {
  background-color: #333;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-left: 5px solid #ffcc00;
}

.service-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.service-title {
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.service-description {
  color: #cccccc;
  font-size: 1rem;
}

.questions-section {
  background-color: #2a2a2a;
  padding: 30px;
  border-radius: 12px;
}

.question-box {
  background-color: #3a3a3a;
  padding: 20px;
  border-left: 5px solid #ffcc00;
  border-radius: 8px;
  margin-bottom: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.question-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.question-title {
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.question-answer {
  color: #cccccc;
  font-size: 1rem;
  font-weight: normal;
}
</style>
