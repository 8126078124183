<template>
  <v-app>
    <v-main class="background">
      <ServiceNavBar>
      </ServiceNavBar>
      <v-container fluid>
        <v-card class="calendar-appointments-card">
          <v-row>
            <v-col cols="12"
                   md="6">
              <v-card-title class="text-h5 text-center">
                Select Day for Service
              </v-card-title>
              <v-card-text>
                <v-date-picker
                    v-model="selectedDate"
                    @input="fetchAppointmentsForDate"
                    :min="minDate"
                    class="calendar-dark"
                    :color="config.page_accent_color"
                    bg-color="transparent"
                    elevation="5"
                    dark
                    show-week
                    min-width="700"/>
              </v-card-text>
            </v-col>
            <v-col cols="12"
                   md="6"
                   class="appointments-container">
              <v-card-title class="text-h5 text-center">
                When would you like to schedule service?
              </v-card-title>
              <v-row class="appointments-buttons"
                     justify="center"
                     align="center">
                <v-col cols="4"
                       v-for="(slot, index) in timeSlots"
                       :key="index">
                  <v-btn :class="{'selected-time': selectedTime === slot}"
                         :disabled="isTimeSlotTaken(slot)"
                         @click="selectTime(slot)"
                         class="appointment-btn"
                         outlined
                         large
                         block>
                    {{ slot }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row v-if="selectedTime">
            <v-col cols="12">
              <v-btn block
                     class="confirm-appointment-btn"
                     :style="{ backgroundColor: config.page_accent_color }"
                     @click="openDialog">
                Confirm Appointment
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
        <v-dialog v-model="dialog"
                  max-width="800px">
          <v-card class="popup-card">
            <v-card-title class="text-h5">
              Appointment Details
            </v-card-title>
            <v-card-text>
              <v-form ref="form">
                <v-text-field v-model="name"
                              label="Name"
                              required/>
                <v-text-field v-model="email"
                              label="Email"
                              required/>
                <v-text-field v-model="phone"
                              label="Phone Number"
                              required/>
                <v-divider class="my-4">
                </v-divider>
                <v-select :items="locations"
                          v-model="selectedLocation"
                          label="Location"
                          item-value="id"
                          item-title="name"
                          required/>
                <v-divider class="my-4">
                </v-divider>
                <v-select v-model="year"
                          :items="years"
                          label="Year of Car"
                          required/>
                <v-text-field v-model="make"
                              label="Make of Car"
                              required/>
                <v-text-field v-model="model"
                              label="Model of Car"
                              required/>
                <v-divider class="my-4">
                </v-divider>
                <v-file-input v-model="issueImage"
                              label="Upload Image for Issue (Optional)"
                              accept="image/*"
                              prepend-icon="mdi-camera"/>
                <v-textarea v-model="issueDescription"
                            label="Describe the Issue"
                            rows="3"/>
                <v-checkbox v-model="textNotifications"
                            label="Send me text notifications of my appointment status. *Messaging & Data Rates Apply Please Check the Terms of Your Carrier."
                            required/>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-btn text
                     @click="closeDialog">
                Cancel
              </v-btn>
              <v-btn text
                     @click="submitAppointment">
                Submit
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
    </v-main>
    <Footer :config="config"></Footer>
  </v-app>
</template>
<script>
import axios from "axios";
import {mapActions, mapGetters} from "vuex";
import ServiceNavBar from "@/views/service/components/ServiceNavBar.vue";
import Footer from "@/views/service/components/Footer.vue";

export default {
  components: {
    ServiceNavBar,
    Footer,
  },
  data() {
    return {
      selectedDate: new Date(),
      appointments: [],
      minDate: new Date().toISOString().substr(0, 10),
      timeSlots: this.generateTimeSlots(),
      selectedTime: null,
      dialog: false,
      name: "",
      email: "",
      phone: "",
      year: null,
      make: "",
      model: "",
      issueImage: null,
      issueDescription: "",
      textNotifications: true,
      years: Array.from({length: 80}, (v, k) => k + 1950),
      locations: [],
      selectedLocation: null,
    };
  },
  created() {
    this.fetchConfig();
    this.fetchAppointmentsForDate();
    this.fetchLocations();
  },
  watch: {
    selectedDate(newDate) {
      this.fetchAppointmentsForDate(newDate);
    },
  },
  computed: {
    ...mapGetters(["config"]),
  },
  methods: {
    ...mapActions(["fetchConfig"]),
    async fetchLocations() {
      try {
        const response = await axios.get("/locations");
        this.locations = response.data;
      } catch (error) {
        console.error("Error fetching locations:", error);
      }
    },
    generateTimeSlots() {
      const slots = [];
      let hour = 9;
      let minutes = 0;
      const endHour = 17;
      while (hour < endHour || (hour === endHour && minutes === 0)) {
        let amPm = hour >= 12 ? "PM" : "AM";
        let displayHour = hour > 12 ? hour - 12 : hour;
        if (displayHour === 0) displayHour = 12;
        const time = `${displayHour}:${minutes === 0 ? "00" : "30"} ${amPm}`;
        slots.push(time);

        if (minutes === 0) {
          minutes = 30;
        } else {
          minutes = 0;
          hour++;
        }
      }
      return slots;
    },
    async fetchAppointmentsForDate(date = null) {
      const formattedDate = (date || this.selectedDate).toISOString().substr(0, 10);
      try {
        const response = await axios.get("/appointments", {
          params: {start_date: formattedDate},
        });
        this.appointments = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    convertTo12Hour(time) {
      const [hours, minutes] = time.split(":");
      const hour = parseInt(hours, 10);
      const amPm = hour >= 12 ? "PM" : "AM";
      const displayHour = hour > 12 ? hour - 12 : hour === 0 ? 12 : hour;
      return `${displayHour}:${minutes} ${amPm}`;
    },
    isTimeSlotTaken(slot) {
      return this.appointments.some((appointment) => {
        const appointmentTime = this.convertTo12Hour(appointment.start_time.substr(0, 5));
        return appointmentTime === slot;
      });
    },
    selectTime(slot) {
      if (!this.isTimeSlotTaken(slot)) {
        this.selectedTime = slot;
      }
    },
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    convertTo24Hour(time) {
      const [timePart, amPm] = time.split(" ");
      let [hours, minutes] = timePart.split(":");
      if (amPm === "PM" && hours !== "12") {
        hours = parseInt(hours) + 12;
      } else if (amPm === "AM" && hours === "12") {
        hours = "00";
      }
      return `${hours}:${minutes}`;
    },
    async submitAppointment() {
      if (this.$refs.form.validate()) {
        const formData = new FormData();
        formData.append("name", this.name);
        formData.append("email", this.email);
        formData.append("phone_number", this.phone);
        formData.append("year_of_car", this.year);
        formData.append("make_of_car", this.make);
        formData.append("model_of_car", this.model);
        formData.append("description_of_issue", this.issueDescription);
        formData.append("text_notifications", this.textNotifications);
        formData.append("start_date", this.selectedDate.toISOString().substr(0, 10));
        formData.append("start_time", this.convertTo24Hour(this.selectedTime));
        formData.append("location_id", this.selectedLocation);
        if (this.issueImage) {
          formData.append("image", this.issueImage);
        }
        try {
          await axios.post("/appointments", formData);
          this.appointmentSubmitted();
          this.selectedTime = null;
          await this.fetchAppointmentsForDate();
          this.closeDialog();
        } catch (error) {
          this.closeDialog();
          this.appointmentError();
        }
      }
    },
    appointmentSubmitted() {
      this.$swal.fire({
        title: '<p class="text-white">Confirmed</p>',
        html: '<strong class="text-white">Check your email and / or phone for a notification about your appointment status.</strong>',
        confirmButtonText: 'Close',
        confirmButtonColor: "white",
        icon: 'success',
        background: '#1c1c1c',
        padding: '2em',
        timer: 3500,
      })
    },
    appointmentError() {
      this.$swal.fire({
        title: '<p class="text-white">Error</p>',
        html: `<strong class="text-white">Appointment could not be created, please check form information.</strong>`,
        confirmButtonText: 'Close',
        confirmButtonColor: "white",
        icon: 'error',
        background: '#1c1c1c',
        padding: '2em',
        timer: 3500,
      })
    },
  },
};
</script>
<style scoped>
.background {
  background-color: #1c1c1c;
  color: white;
  padding-bottom: 40px;
}

.calendar-appointments-card {
  background-color: #2a2a2a;
  color: white;
  border-radius: 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  padding: 20px;
}

.calendar-dark .v-picker__body {
  background-color: #333;
  color: white;
}

.appointments-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.appointments-buttons {
  margin-top: 10px;
}

.appointment-btn {
  background-color: #ffcc00;
  color: black;
  font-weight: bold;
  margin-bottom: 10px;
  transition: background-color 0.3s ease;
}

.appointment-btn[disabled] {
  background-color: #777;
  color: white;
}

.appointment-btn:hover:not([disabled]) {
  background-color: #ffc107;
}

.selected-time {
  background-color: #00cc66 !important;
  color: white !important;
}

.confirm-appointment-btn {
  margin-top: 20px;
  color: white;
}

.popup-card {
  background-color: #2a2a2a;
  color: white;
  padding: 20px;
  border-radius: 12px;
}
</style>
