<template>
  <v-app>
    <v-main class="fill-height">
      <ServiceNavBar>
      </ServiceNavBar>

      <v-container class="d-flex align-center justify-center fill-height">
        <v-card class="pa-5 register-card elevation-10"
                min-width="800">
          <v-card-title class="text-center register-title">
            Register for an Account
          </v-card-title>
          <v-card-text>
            <v-form @submit.prevent="submitRegister"
                    ref="form">
              <v-text-field label="Name *"
                            v-model="name"
                            outlined
                            dense
                            clearable
                            :rules="[v => !!v || 'Name is required']"
                            required
                            class="mb-2"></v-text-field>
              <v-text-field label="Email *"
                            v-model="email"
                            outlined
                            dense
                            clearable
                            :rules="[v => !!v || 'Email is required']"
                            required
                            class="mb-2"></v-text-field>
              <v-text-field label="Zip Code (Optional)" v-model="zipCode" outlined dense clearable
                            type="number"
                            class="mb-2"></v-text-field>
              <v-text-field class="mb-2"
                            label="Password *"
                            v-model="password"
                            type="password"
                            outlined
                            dense
                            clearable
                            :rules="[v => !!v || 'Password is required']"
                            required></v-text-field>
              <v-text-field class="mb-2"
                            label="Confirm Password *"
                            v-model="confirmPassword"
                            type="password"
                            outlined
                            dense
                            clearable
                            :rules="[v => !!v || 'Password confirmation is required']"
                            required></v-text-field>
              <h2 class="text-white text-center mt-10">
                Vehicle Information
              </h2>
              <v-divider class="mb-5">
              </v-divider>
              <v-select class="mb-2"
                        label="Year (Optional)"
                        v-model="year"
                        :items="yearList"
                        outlined
                        dense
                        clearable>
              </v-select>

              <v-text-field class="mb-2"
                            label="Make (Optional)"
                            v-model="make"
                            outlined
                            dense
                            clearable>
              </v-text-field>
              <v-text-field class="mb-2"
                            label="Model (Optional)"
                            v-model="model"
                            outlined
                            dense
                            clearable>
              </v-text-field>
              <v-btn :style="{ backgroundColor: '#3a4ba3', color: '#ffffff' }"
                     block
                     class="mt-4 register-btn"
                     type="submit"
                     elevation="2">
                Register
              </v-btn>
            </v-form>
          </v-card-text>
          <v-card-actions class="d-flex justify-center">
            <v-btn text
                   @click="$router.push('/service-login')"
                   class="login-btn">
              Already have an account? Login
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-container>
    </v-main>
    <Footer :config="config"></Footer>
  </v-app>
</template>
<script>
import axios from "axios";
import ServiceNavBar from "@/views/service/components/ServiceNavBar.vue";
import Footer from "@/views/service/components/Footer.vue";
import {mapActions, mapGetters} from "vuex";

export default {
  components: {
    ServiceNavBar,
    Footer,
  },
  data() {
    return {
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
      year: '',
      make: '',
      model: '',
      zipCode: '',
      yearList: [],
    };
  },
  created() {
    this.generateYearList();
  },
  computed: {
    ...mapGetters(['config']),
  },
  methods: {
    ...mapActions(['fetchConfig']),
    generateYearList() {
      const currentYear = new Date().getFullYear();
      this.yearList = Array.from({length: currentYear - 1950 + 1}, (_, i) => (1950 + i).toString());
      console.log(currentYear);
    },
    async submitRegister() {
      if (!this.$refs.form.validate()) return;

      if (this.password !== this.confirmPassword) {
        console.error("Passwords do not match");
        return;
      }
      try {
        const response = await axios.post('https://demo.swiftsolutions.app/api/register', {
          name: this.name,
          email: this.email,
          password: this.password,
          password_confirmation: this.confirmPassword,
          employee: false,
          manager: false,
          year: this.year,
          make: this.make,
          model: this.model,
          zipCode: this.zipCode,
        });
        const token = response.data.access_token;
        localStorage.setItem('token', token);
        this.$router.push('/service-login');
      } catch (error) {
        console.error("Registration failed", error);
      }
    }
  }
};
</script>
<style scoped>
.fill-height {
  min-height: 100vh;
  background-color: #1c1c1c;
  display: flex;
  align-items: center;
  justify-content: center;
}

.register-card {
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  background: #333;
}

.register-title {
  color: #cfcfff;
  font-size: 1.8rem;
  margin-bottom: 16px;
  font-weight: bold;
}

.register-btn {
  font-size: 1rem;
  font-weight: bold;
  background-color: #3a4ba3;
  color: white;
  transition: background-color 0.3s ease;
}

.register-btn:hover {
  background-color: #5567c9;
}

.login-btn {
  color: #cfcfff;
  font-weight: bold;
}

.v-text-field {
  background-color: #2a2a2a;
  color: #fff;
  border-radius: 5px;
}
</style>
