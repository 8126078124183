<template>
  <v-app-bar app
             flat
             color="transparent"
             class="transparent-navbar">
    <v-row align="center"
           no-gutters>
      <v-col cols="auto"
             class="d-flex align-center pl-4">
        <v-icon left
                color="white"
                class="mr-2">
          mdi-tools
        </v-icon>
        <v-toolbar-title class="text-white m-0">
          {{ config.appname }}
        </v-toolbar-title>
      </v-col>
    </v-row>
    <v-spacer>
    </v-spacer>
    <v-btn text
           @click="$router.push('/service-appointment')"
           class="nav-btn">
      Book Today
    </v-btn>
    <v-btn text
           @click="$router.push('/')"
           class="nav-btn">
      Services
    </v-btn>
    <v-btn text
           @click="$router.push('/service-parts')"
           class="nav-btn">
      Parts
    </v-btn>
    <v-btn text
           @click="$router.push('/service-events')"
           class="nav-btn">
      Events
    </v-btn>
    <v-btn text
           @click="$router.push('/service-showcase')"
           class="nav-btn">
      Showcase
    </v-btn>
    <v-btn text
           @click="$router.push('/service-contact')"
           class="nav-btn">
      Contact
    </v-btn>
    <v-btn text
           @click="handleAuthButton"
           class="nav-btn">
      {{ isLoggedIn ? 'Logout' : 'Login' }}
    </v-btn>

    <v-btn icon
           @click="toggleCartDialog">
      <v-icon :color="config.page_accent_color">
        mdi-cart
      </v-icon>
      <span :style="{ color: config.page_accent_color }">
        {{ cartCount }}
      </span>
    </v-btn>
    <v-dialog v-model="cartDialog"
              max-width="500">
      <v-card>
        <v-card-title class="headline">
          Shopping Cart
        </v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item v-for="item in cartItems"
                         :key="item.id">
              <v-row align="center"
                     justify="space-between"
                     class="cart-item">
                <v-col cols="6">
                  <strong>
                    {{ item.name }}
                  </strong>
                  <p>
                    Price: ${{ item.price.toFixed(2) }}
                  </p>
                </v-col>
                <v-col cols="3">
                  <v-text-field v-model.number="item.quantity"
                                type="number"
                                min="1"
                                class="quantity-input"
                                label="Qty"
                                hide-details
                                outlined
                                dense
                                solo
                                @change="updateCartQuantity(item)">
                  </v-text-field>
                </v-col>
                <v-col cols="1">
                  <v-btn icon
                         @click="removeFromCart(item.id)">
                    <v-icon>
                      mdi-close
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-list-item>
          </v-list>
          <p class="cart-total">
            Total: ${{ cartTotal.toFixed(2) }}
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary"
                 block
                 @click="proceedToCheckout">
            Checkout
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app-bar>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';

export default {
  data() {
    return {
      cartDialog: false,
    };
  },
  computed: {
    ...mapGetters(['cartItems', 'cartTotal', 'cartCount', 'isLoggedIn', 'config']),
  },
  created() {
    this.fetchConfig();
  },
  methods: {
    ...mapActions(['removeFromCart', 'addToCart', 'updateCartQuantity', 'logout', 'fetchConfig']),

    toggleCartDialog() {
      this.cartDialog = !this.cartDialog;
    },
    proceedToCheckout() {
      this.$router.push('/checkout');
    },
    handleAuthButton() {
      if (this.isLoggedIn) {
        this.logout();
        this.$router.push('/login');
      } else {
        this.$router.push('/login');
      }
    },
    updateCartQuantity(item) {
      if (item.quantity > 0) {
        this.updateCartQuantity({...item});
      }
    }
  },
};
</script>

<style scoped>
.transparent-navbar {
  background-color: transparent !important;
  box-shadow: none;
}

.nav-btn {
  color: white;
  font-weight: bold;
  margin: 0 10px;
  transition: color 0.3s ease;
}

.nav-btn:hover {
  color: white;
}

.v-app-bar {
  display: flex;
  justify-content: flex-start;
}

.cart-total {
  font-weight: bold;
  text-align: right;
  margin-top: 10px;
}

.quantity-input {
  max-width: 60px;
}
</style>
