<template>
  <v-app>
    <v-main class="fill-height">
      <ServiceNavBar></ServiceNavBar>
      <v-container class="d-flex align-center justify-center">
        <v-card class="pa-5 login-card elevation-10" max-width="600">
          <v-card-title class="headline text-center login-title">Welcome Back!</v-card-title>
          <v-card-text>
            <v-form @submit.prevent="submitLogin">
              <v-text-field label="Email"
                            v-model="email"
                            prepend-inner-icon="mdi-email"
                            outlined
                            dense
                            clearable
                            required>
              </v-text-field>
              <v-text-field label="Password"
                            v-model="password"
                            type="password"
                            prepend-inner-icon="mdi-lock"
                            outlined
                            dense
                            clearable
                            required>
              </v-text-field>
              <v-btn :style="{ backgroundColor: '#3a4ba3', color: '#ffffff' }"
                     block
                     class="mt-4 login-btn"
                     type="submit"
                     :loading="isSubmitting"
                     elevation="2">
                Login
              </v-btn>
            </v-form>
          </v-card-text>
          <v-card-actions class="d-flex justify-center">
            <v-btn text @click="$router.push('/service-register')" class="create-account-btn">Don't have an account?
              Register
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn text href="https://app.swiftsolutions.app/staff/login" class="employees-btn">Staff Login</v-btn>
          </v-card-actions>
        </v-card>
      </v-container>
    </v-main>
    <Footer :config="config">
    </Footer>
  </v-app>
</template>

<script>
import axios from 'axios';
import ServiceNavBar from "@/views/service/components/ServiceNavBar.vue";
import Footer from "@/views/service/components/Footer.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    ServiceNavBar,
    Footer,
  },
  data() {
    return {
      email: '',
      password: '',
      isSubmitting: false,
    };
  },
  created() {
    this.fetchConfig();
  },
  computed: {
    ...mapGetters(["config"]),
  },
  methods: {
    ...mapActions(['fetchConfig']),
    async submitLogin() {
      if (this.isSubmitting) return;
      this.isSubmitting = true;
      try {
        const response = await axios.post('/customer/login', {
          email: this.email,
          password: this.password,
        });
        const token = response.data.access_token;
        localStorage.setItem('token', token);
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        window.location.href = '/';
      } catch (error) {
        console.error("Login failed:", error);
      } finally {
        this.isSubmitting = false;
      }
    },
  },
};
</script>

<style scoped>
.fill-height {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1c1c1c; /* Dark background */
}

.login-card {
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  background: #333; /* Matching the card styling */
}

.login-title {
  color: #cfcfff; /* Blueish color */
  font-size: 1.8rem;
  margin-bottom: 16px;
  font-weight: bold;
}

.login-btn {
  font-size: 1rem;
  font-weight: bold;
  background-color: #3a4ba3; /* Blueish button color */
  color: white;
  transition: background-color 0.3s ease;
}

.login-btn:hover {
  background-color: #5567c9; /* Lighter blue on hover */
}

.create-account-btn, .employees-btn {
  color: #cfcfff;
  font-weight: bold;
}

.v-text-field {
  background-color: #2a2a2a;
  color: #fff;
  border-radius: 5px;
}
</style>
