<template>
  <v-app class="parts-page">
    <v-main>
      <v-container fluid>
        <ServiceNavBar>
        </ServiceNavBar>
        <v-row v-if="!selectedSection"
               class="selection-screen"
               justify="center"
               align="center">
          <v-col cols="12"
                 md="3"
                 class="d-flex justify-center">
            <v-card class="selection-tile dark-card"
                    @click="selectSection('Performance')"
                    outlined>
              <v-img
                  src="https://media.gettyimages.com/id/155598220/photo/car-parts.jpg?s=612x612&w=gi&k=20&c=mQcWlojs6SQj5fJZw4f6sMcPudXdnyqnuZxvcw4EVjA="
                  height="200px"
                  contain>
              </v-img>
              <v-card-title class="text-h6 font-weight-bold text-white">
                Performance
              </v-card-title>
            </v-card>
          </v-col>
          <v-col cols="12"
                 md="3"
                 class="d-flex justify-center">
            <v-card class="selection-tile dark-card"
                    @click="selectSection('Wheels and Tires')"
                    outlined>
              <v-img
                  src="https://st3.depositphotos.com/1177973/18399/i/450/depositphotos_183998900-stock-photo-tires-in-service-center.jpg"
                  height="200px"
                  contain>

              </v-img>
              <v-card-title class="text-h6 font-weight-bold text-white">
                Wheels and Tires
              </v-card-title>
            </v-card>
          </v-col>
          <v-col cols="12"
                 md="3"
                 class="d-flex justify-center">
            <v-card class="selection-tile dark-card"
                    @click="selectSection('General')"
                    outlined>
              <v-img
                  src="https://media.istockphoto.com/id/478107962/photo/auto-parts.jpg?s=612x612&w=0&k=20&c=C31mE-cVYFlLqJp9smDKUczPoBEtoYl5gaGxdvH0lmM="
                  height="200px"
                  contain>
              </v-img>
              <v-card-title class="text-h6 font-weight-bold text-white">
                General
              </v-card-title>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="selectedSection"
               justify="center">
          <v-btn color="blue darken-2"
                 class="mb-4"
                 outlined
                 @click="resetSelection">
            Back to Selection
          </v-btn>
        </v-row>
        <v-row v-if="selectedSection === 'General'"
               class="category-select"
               justify="center"
               align="center">
          <v-select v-model="selectedCategory"
                    :items="categories"
                    label="Select a Category"
                    outlined
                    dense
                    hide-details
                    class="category-dropdown dark-dropdown"
                    @change="filterParts">
          </v-select>
        </v-row>
        <v-row v-if="selectedSection === 'General'"
               justify="center"
               class="product-grid">
          <v-col v-for="part in filteredParts"
                 :key="part.id"
                 cols="12"
                 md="4"
                 class="d-flex align-stretch">
            <v-card class="part-card dark-card"
                    elevation="3">
              <v-img :src="part.image"
                     height="200px"
                     class="align-center">
              </v-img>
              <v-card-title class="text-h6 font-weight-bold text-white">
                {{ part.name }}
              </v-card-title>
              <v-card-subtitle class="font-weight-regular text-subtitle-2 grey--text text-center">
                {{ part.category }}
              </v-card-subtitle>
              <v-card-text class="text-white">
                <div class="part-description">
                  {{ part.description }}
                </div>
              </v-card-text>
              <v-card-actions class="justify-space-between">
                <v-chip color="blue darken-2"
                        class="white--text font-weight-bold">
                  ${{ part.price.toFixed(2) }}
                </v-chip>
                <v-btn color="blue darken-2"
                       @click="addToCart(part)"
                       outlined>
                  Add to Cart
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="selectedSection === 'Performance' || selectedSection === 'Wheels and Tires'"
               justify="center"
               class="product-grid">
          <v-col cols="12"
                 md="4"
                 class="d-flex align-stretch">
            <v-card class="part-card dark-card"
                    elevation="3">
              <v-card-title class="text-h6 font-weight-bold text-white text-center">
                Test
              </v-card-title>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <Footer :config="config">
    </Footer>
  </v-app>
</template>
<script>
import ServiceNavBar from "@/views/service/components/ServiceNavBar.vue";
import {mapActions, mapGetters} from "vuex";
import Footer from "@/views/service/components/Footer.vue";

export default {
  components: {
    ServiceNavBar,
    Footer,
  },
  data() {
    return {
      selectedSection: null,
      selectedCategory: '',
      categories: ["All", "Engine", "Suspension", "Brakes", "Exhaust", "Electrical", "Interior"],
      parts: [],
      filteredParts: [],
    };
  },
  created() {
    this.fetchConfig();
    this.filteredParts = this.parts;
  },
  computed: {
    ...mapGetters(['config']),
  },
  methods: {
    ...mapActions(["addToCart", "fetchConfig"]),
    selectSection(section) {
      this.selectedSection = section;
    },
    filterParts() {
      this.filteredParts = this.selectedCategory === "All" || !this.selectedCategory
          ? this.parts
          : this.parts.filter(part => part.category === this.selectedCategory);
    },
    resetSelection() {
      this.selectedSection = null;
      this.selectedCategory = '';
    },
  },
};
</script>

<style scoped>
.parts-page {
  background-color: #1c1c1c;
  padding-bottom: 40px;
}

.selection-screen {
  margin: 50px 0;
}

.selection-tile {
  cursor: pointer;
  background-color: #2a2a2a;
  color: white;
  transition: transform 0.3s, box-shadow 0.3s;
  border-radius: 20px;
  overflow: hidden;
}

.selection-tile:hover {
  transform: translateY(-5px);
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.3);
}

.dark-card {
  background-color: #2a2a2a;
  color: white;
  border-radius: 12px;
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
}

.dark-card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.3);
}

.category-select {
  margin: 20px 0;
}

.category-dropdown {
  width: 300px;
}

.product-grid {
  margin-top: 20px;
}

.part-card {
  margin: 15px;
}

.part-description {
  text-align: justify;
  padding: 0 10px;
}
</style>
